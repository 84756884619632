<template>
  <div class="list-two">
    <div class="filter-container">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="客户姓名">
          <el-input size="small"
            clearable
            v-model="formInline.customerName"
            placeholder="请输入客户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户电话">
          <el-input size="small"
            clearable
            v-model="formInline.customerTel"
            placeholder="请输入客户电话"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList(true)" size="small">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" style="max-height: 450px; overflow-y: auto; width: 100%" height="450" border size="small"
      stripe tooltip-effect="dark">
      <el-table-column prop="customerName" label="客户姓名" width="140">
        <template v-slot="scope">
          <span style="color: #f2694a">{{ scope.row.customerName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="customerTel" label="客户电话" width="140"></el-table-column>
      <el-table-column prop="customerAddress" width="260" label="客户详细地址"></el-table-column>
      <el-table-column prop="plannedVisitStore" label="计划到访门店" width="280"></el-table-column>
      <el-table-column width="220" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button 
          :class="['button-width']"
            :disabled="scope.row.redeemed === 1"
            @click="redeemGift(scope.row)"
            size="mini"
            :type="scope.row.redeemed ? 'success' : 'primary'"
            >{{ scope.row.redeemed === 1 ? "已领取" : "领取礼品" }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getRegListSaiHan, redeemCustomerGiftSaiHan } from "../../api/user";

export default {
  data() {
    return {
      formInline: {
        customerName: "",
        customerTel: "",
      },
      search: '', // 用于搜索的字段
      tableData: [],
      total: 0,
      pageNo: 1,
      pageSize: 10,
    };
  },
  
 methods: {
    async getList(isSearch = false) {
      // 如果是搜索操作，强制设置当前页数为1
      if (isSearch) {
        this.pageNo = 1;
      }

      const params = {
        customerName: this.formInline.customerName,
        customerTel: this.formInline.customerTel,
        search: this.search, // 添加搜索的参数
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };

      try {
        const res = await getRegListSaiHan(params);
        const { records, total } = res.data.data.data;
        records.forEach(record => {
          record.redeemed = record.redeemed || false;
        });
        this.tableData = records;
        this.total = total;
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    },

    handleSizeChange(size) {
      this.pageSize = size;
      this.getList();
    },

    handleCurrentChange(page) {
      this.pageNo = page;
      this.getList();
    },

    async redeemGift(row) {
      this.$confirm("确定领取?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const user = JSON.parse(localStorage.getItem('user')); 
          const username = user[0].username; 
      
          const arr = {
            id: row.id,
            redeemed: true,
            redeemedBy: username, 
          };

          try {
            const res = await redeemCustomerGiftSaiHan(arr);
            if (res.data.code === 200) {
              this.$message.success("领取成功");
              row.redeemed = 1;
              row.redeemedBy = username;
            } else {
              this.$message.error("更新失败");
            }
            this.getList();
          } catch (err) {
            this.$message.error("领取失败，请重试");
            console.log("error submit!! 报错");
          }
        })
        .catch(() => {
          console.log("取消领取");
        });
    },
  },

  created() {
    this.getList();
  },
};
</script>

<style scoped>
.button-width {
  width: 85px;
}
.el-table thead {
  color: #1f2225 !important;
}
</style>

<style lang="scss" scoped>
.el-main {
  padding: 0 !important;
}
</style>
